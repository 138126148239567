import logo from './logo.svg';
import './App.css';
import './Media.css';
import React from 'react';
function ValidForm(props) {
    var dataValidReg = false;
    var objDataUser = {
        mail:"",
        pass:"",
    };
    var objDataUserName = {
        mail:["E-mail","email"],
        pass:["Пароль","password"],
    };
    //=================================================
    function onChangeIn(event){
        objDataUser[event.target.getAttribute("data-in")] = event.target.value;
    }
    //=================================================
    function validFormIn(event){
        let jaxBool = true;
        let formData = new FormData();
        for (let key in objDataUser) {
            if (objDataUser.hasOwnProperty(key)) {
                if(objDataUser[key] == "" && !objDataUser[key]){
                    jaxBool = false;
                    document.querySelector("[data-in='"+key+"']").style.backgroundColor = "#ea3535";
                }else{
                    formData.append(key, objDataUser[key]);
                }
            }
        }
        if(jaxBool){
            let xhr = new XMLHttpRequest();
            xhr.open("POST", "https://elec-commerc.com/static/api/in/", false);
            try {
                xhr.send(formData);
                if (xhr.status != 200) {
                    console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                } else {
                    let qry = JSON.parse(xhr.responseText);
                    if(!qry["query"]){
                        document.querySelector('[data-jam="error"]').innerHTML = qry["messeg"];
                    }else{
                        localStorage.setItem("key_s", qry["messeg"]);
                        window.location.href = "/main/";
                    }
                }
             } catch(err) {
                 console.log("Запрос не удался",err);
             }
        }
    }
    let getContentFormIn = objDataUserName => { 
        let content = [];
        for (var key in objDataUserName) {
            content.push( 
                <div class="jam-form-input">
                    <label class="jam-div" >{objDataUserName[key][0]}</label>
                    <input data-in={key} class="jam-input" type={objDataUserName[key][1]} onChange={onChangeIn} />
                </div>
            );
        }
        return content;
    }

    let vc = 7;
    return (
        <div >
            <div class="jam-form" id="valid" style={{display: 'flex'}}>
                <div class="jam-form-control">
                    <div class="jam-form-head">
                        <div class="jam-h">
                            <div>Вход</div>
                        </div>
                    </div>
                    <div class="jam-form-control-input">
                        {getContentFormIn(objDataUserName)}
                        <div class="jam-error">
                            <div data-jam="error" class="jam-div-button"> </div>
                        </div>
                        <div class="jam-form-control-button">
                        <div class="jam-form-button">
                            <div data-valid="valid" class="jam-button" onClick={validFormIn}><div class="jam-div-button">Войти</div></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ValidForm;