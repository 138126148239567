import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ValidRegForm from './ValidForm.js';
import Main from './Main.js';
import Roll from './Roll.js';
import Subdivision from './Subdivision.js';
import AddMain from './AddMain.js';
import Users from './Users.js';
import Counterparty from './Counterparty.js';
import Applicant from './Applicant.js';
import Applicationadd from './Applicationadd.js';

import { BrowserRouter as Router, Route, Routes, useParams, Outlet } from 'react-router-dom';
var objAllApi ;
var contentHead ;
var contentAddMain ;
var contentDataMain ;
var contentDataRoll ;
var contentDataSubdivision ;


var contentDataUsers;
var contentDataTypeMain;
var contentDataCounterparty;
var contentDataCounterparty;
var contentDataApplicant;
var contentDataComments;
var contentDataPriority;
var contentDataStatus ;
var contentPageCount;

const root = ReactDOM.createRoot(document.getElementById('root'));

let keys = localStorage.getItem("key_s");
let xhr = new XMLHttpRequest();
function User(){ 
    xhr.open("GET", "https://elec-commerc.com/static/api/datausers/?keys="+keys, false);
    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataMain = objAllApi.users;
            contentDataRoll = objAllApi.roll;
            contentDataSubdivision = objAllApi.subdivision;
            
        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }

    return <div>
                <Users 
                    objAllApi={objAllApi}  
                    contentHead={contentHead}  
                    contentAddMain={contentAddMain} 
                    contentDataMain={contentDataMain} 
                    contentDataRoll={contentDataRoll} 
                    contentDataSubdivision={contentDataSubdivision}  
                    />
            </div>;
}


function Mains(){
    xhr.open("GET", "https://elec-commerc.com/static/api/addmain/?keys="+keys+"&page=1", false);
    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataMain = objAllApi.application;
            contentDataUsers = objAllApi.users;
            contentDataTypeMain = objAllApi.type_application[0];
            contentDataCounterparty = objAllApi.counterparty[0];
            contentDataApplicant = objAllApi.applicant[0];
            contentDataComments = objAllApi.comments[0];
            contentDataPriority = objAllApi.priority[0];
            contentDataStatus = objAllApi.status[0];
            contentDataSubdivision = objAllApi.subdivision;
            contentPageCount = objAllApi.applicationcount[0];
            console.log(objAllApi);

        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }
    return <div>
                <Main 
                    objAllApi={objAllApi}  
                    contentHead={contentHead}  
                    contentAddMain={contentAddMain} 
                    contentDataMain={contentDataMain} 
                    contentDataUsers={contentDataUsers} 
                    contentDataTypeMain={contentDataTypeMain}  
                    contentDataCounterparty={contentDataCounterparty}
                    contentDataApplicant={contentDataApplicant}
                    contentDataComments={contentDataComments}
                    contentDataPriority={contentDataPriority}
                    contentDataStatus={contentDataStatus}
                    contentDataSubdivision={contentDataSubdivision}
                    contentPageCount={contentPageCount}
                    />
            </div>;
}

root.render(
<Router>
    <Routes>
        <Route path="/" element={<ValidRegForm />} />
        <Route path="/main" element={<Mains  />} />
        <Route path="/addmain/" element={<AddMain  />} />
        <Route path="/roll" element={<Roll  />} />
        <Route path="/subdivision" element={<Subdivision  />} />
        <Route path="/users" element={<User  />} />
        <Route path="/counterparty" element={<Counterparty  />} />
        <Route path="/applicant" element={<Applicant  />} />
        <Route path="/applicationadd" element={<Applicationadd  />} />
    </Routes>
</Router> 
);


